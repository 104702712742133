import React from 'react'
import { Link, withPrefix } from 'gatsby'
import styled from 'styled-components'
import { GrammarCategoryBadge, GrammarCategory } from './GrammarCategoryBadge'
import { LocaleConfig, useTranslations } from '../core'

interface GrammarVideoCardProps {
    locale: LocaleConfig
    category: GrammarCategory
    title: string
    description: string
    length: string
    path: string
}

export const GrammarVideoCard = ({
    locale,
    category,
    title,
    description,
    length,
    path,
}: GrammarVideoCardProps) => {
    const { translate } = useTranslations(locale.locale)

    return (
        <VideoCard to={path} data-testid="grammar-video-card">
            <Content>
                <GrammarCategoryBadge locale={locale} category={category} />
                <Title>
                    {title}
                    <Length>({length})</Length>
                </Title>
                <Description>{description}</Description>
            </Content>
            <Controls>
                <PlayIcon />
                {translate('grammar_video_check')}
            </Controls>
        </VideoCard>
    )
}

const VideoCard = styled(Link)`
    width: 472px;
    position: relative;
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.accent};
    border-radius: 16px;
    background: white;
    z-index: 1;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 24px;
    transition: transform 0.3s ease-in-out;

    @media ${({ theme }) => theme.custom_breakpoints.grammar_videos} {
        width: 100%;
        flex-direction: column;
        margin-bottom: 16px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: ${({ theme }) => theme.shadows.large};
        border-radius: 16px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    @media (hover: hover) {
        &:hover {
            transform: translateY(-8px);

            &:after {
                opacity: 1;
            }
        }
    }
`

const Content = styled.div`
    flex: 1 1 auto;
    padding: 24px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 16px;
    }
`

const Controls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80px;
    background: ${({ theme }) => theme.colors.accent};
    flex-shrink: 0;
    font-size: 12px;
    font-weight: ${({ theme }) => theme.typography.weights.bold};
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    @media ${({ theme }) => theme.custom_breakpoints.grammar_videos} {
        width: 100%;
        height: 40px;
        flex-direction: row;
        border-top-right-radius: 0;
        border-bottom-left-radius: 16px;
    }
`

const Title = styled.h3`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 8px 0;
    font-size: 20px;
    line-height: 30px;
    ${({ theme }) => theme.typography_presets.bold};
`

const Length = styled.span`
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.defaultFontFamily};
    font-weight: ${({ theme }) => theme.typography.weights.medium};
    line-height: 16px;
`

const Description = styled.p`
    font-size: 14px;
    line-height: 26px;
    margin: 0;
`

const PlayIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 74px;
    background: white;
    margin-bottom: 8px;

    @media ${({ theme }) => theme.custom_breakpoints.grammar_videos} {
        margin: 0 8px 0 0;
        width: 24px;
        height: 24px;
    }

    &:after {
        width: 14px;
        height: 15px;
        content: '';
        background-image: url('${withPrefix('/images/icons/play.svg')}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-left: 1px;

        @media ${({ theme }) => theme.custom_breakpoints.grammar_videos} {
            width: 10px;
            height: 10px;
        }
    }
`
