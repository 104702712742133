import React from 'react'
import styled from 'styled-components'
import {
    Layout,
    SEO,
    Container,
    PageTitle,
    LocaleConfig,
    useTranslations,
    SectionSpacer,
} from '../core'
import { GrammarVideoCard } from './GrammarVideoCard'

interface IndexPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
        content: any
    }
}

const IndexPage = ({ pageContext, path }: IndexPageProps) => {
    const { localeConfig, content } = pageContext
    const { translate } = useTranslations(localeConfig.locale)

    return (
        <Layout locale={localeConfig} path={path} hasContactSection={false}>
            <SEO
                title={translate('page_grammarvideos')}
                extraMetas={[{ name: 'robots', content: 'noindex' }]}
            />
            <PageTitle>{translate('page_grammarvideos')}</PageTitle>
            <Container>
                <GrammarVideos>
                    {content.map(
                        ({
                            id,
                            category,
                            title,
                            description,
                            length,
                            slug,
                        }: any) => (
                            <GrammarVideoCard
                                key={id}
                                locale={localeConfig}
                                category={category}
                                title={title}
                                description={description}
                                length={length}
                                path={`/grammar-videos/${slug}`}
                            />
                        )
                    )}
                </GrammarVideos>
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default IndexPage

const GrammarVideos = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
